// ** Default import

import React, { useContext, useEffect, useState } from 'react';

// ** Utils
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DataGridCustomGroup from '@components/UI/DataGridCustomGroup';
import CreateModal from '@components/Teams/CreateModal';
import UpdateModal from '@components/Teams/UpdateModal';
import TeamModal from '@components/Teams/TeamModal';
import { classNames, getFullname } from '@utility/Utils';
import ExportModal from '@components/Teams/ExportModal';
import TeamConfig from '@src/helpers/FiltersConfigs/TeamConfig';
import FilterData from '@src/helpers/FilterData';
import congesService from '@data/congesService';
import { Trash } from 'lucide-react';
// ** FontAwesomeIcon

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faPlus } from '@fortawesome/pro-regular-svg-icons';
// ** UI

import {
  ButtonFilled,
  ButtonThirdActionWithIcon,
  ButtonSecondAction,
  FilterButton,
} from '@components/UI/Buttons';
import Spinner from '@components/UI/Spinner';

import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons';

import useColumns from '@hooks/useColumns';

import UserContext from '@context/UserContext';

import ToastCustom from '@components/UI/ToastCustom';
import useSortables from '@hooks/useSortables';
import { Tooltip } from '@mui/material';

import useJsHelpers from '@hooks/useJsHelpers';
import AddCollaborator from '../components/Planning/components/pageHeader/components/AddCollaborator';

const initialMetaData = {
  perPage: 11,
  current_page: 1,
  last_page: 1,
  total: 0,
  options: [],
};

const initialSort = [{ field: 'lastname', direction: 'asc' }];

const Teams = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState(initialMetaData);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState(initialSort);

  const [tags, setTags] = useState([]);

  const [teamModalData, setTeamModalData] = useState({});
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState({});
  const { currentUser, actionsDirector, actionName, setActionName } =
    useContext(UserContext);

  const isDirector = currentUser?.profile.label === 'DIRECTOR';

  const removeUser = async (user) => {
    await actionsDirector([user], 'detach');
    getUserCounters(metaData.current_page, filters);
  };

  const getUserAfterAdd = async () => {
    await getUserCounters(metaData.current_page, filters);
    setActionName(null);
  };

  useEffect(() => {
    if (actionName === null) return;
    getUserAfterAdd();
  }, [actionName]);

  const [columns, setColumns] = useState([
    {
      field: 'name',
      headerName: t('collaborators'),
      headerClassName: 'title-col text-sm text-lightgrey-300',
      width: 200,
      filterable: false,
      hideable: false,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.name}{' '}
            {row.directors_exists && isDirector && (
              <button onClick={() => removeUser(row)} className='my-auto '>
                <Trash className='text-center text-xs cursor-pointer text-gray-800 font-bold h-3 w-3' />
              </button>
            )}
          </p>
        );
      },
    },
    {
      field: 'teams',
      headerName: t('teams'),
      headerClassName: 'title-col text-sm text-lightgrey-300',
      width: 200,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return params.row.site ? (
          <div
            className='flex items-center gap-x-2 overflow-x-scroll w-full overflow-grab px-1 cursor-pointer'
            onClick={() => {
              setIsTeamModalOpen(true);
              setTeamModalData(params.row);
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='cursor-pointer'
              size='lg'
            />
            {params.value?.length
              ? params.value.map((team, index) => (
                  <Tooltip
                    key={index}
                    enterDelay={100}
                    enterNextDelay={100}
                    leaveDelay={50}
                    arrow
                    disableInteractive
                    title={
                      <div className='flex items-center gap-x-2 '>
                        {params.value?.length
                          ? params.value.map((team, index) => (
                              <span
                                key={team.id}
                                className=' text-white text-xs text-center overflow-hidden overflow-ellipsis whitespace-nowrap'
                              >
                                {team.label}
                              </span>
                            ))
                          : ''}
                      </div>
                    }
                  >
                    <div className='flex justify-center items-center px-2 py-[0.3rem] bg-lightgrey-50 rounded-md'>
                      <span
                        key={team.id}
                        className=' text-gray-900 text-xs text-center overflow-hidden overflow-ellipsis whitespace-nowrap'
                      >
                        {team.label}
                      </span>
                    </div>
                  </Tooltip>
                ))
              : ''}
          </div>
        ) : (
          ''
        );
      },
    },
    {
      field: 'site',
      headerName: t('site'),
      headerClassName: 'title-col text-sm text-lightgrey-300',
      width: 150,
      filterable: false,
      sortable: false,
      hideable: false,
    },
  ]);

  const [rows, setRows] = useState([]);
  const { handleSortModelChange } = useSortables(sort, setSort, 'user');
  const { groupColumn, addColumns } = useColumns(setColumns);
  const { exportDocHelper } = useJsHelpers();

  const getTags = async () => {
    try {
      const tags = await congesService.post('/v1/tags/search', {
        filters: [
          {
            field: 'user_id',
            operator: '=',
            value: currentUser.id,
          },
        ],
      });
      setTags(tags.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserCounters = (page, filters) => {
    let dataToSend = {
      sort: sort,
      filters: [].concat(filters ?? []),
    };

    congesService
      .post(
        `/v1/users/search?include=site,user_leave_counts,tags,user_tags&page=${
          page || metaData.current_page
        }&limit=15`,
        {
          ...dataToSend,
          aggregates: [
            {
              relation: 'directors',
              type: 'exists',
              filters: [
                {
                  field: 'directors.director_id',
                  operator: '=',
                  value: 2,
                },
              ],
            },
          ],
        }
      )
      .then((leaveCounts) => {
        const getOptions = () => {
          const options = [];
          for (let i = 1; i <= leaveCounts.data.meta.last_page; i++) {
            options.push({
              value: i,
              label: i,
            });
          }
          return options;
        };

        // Set Pagination
        setMetaData((prevState) => ({
          ...prevState,
          total: leaveCounts.data.meta.total,
          current_page: leaveCounts.data.meta.current_page,
          per_page: leaveCounts.data.meta.per_page,
          last_page: leaveCounts.data.meta.last_page,
          options: getOptions(),
        }));

        // check if there is 15 rows, else add empty rows
        if (leaveCounts.data.data.length < 15) {
          const emptyRows = 15 - leaveCounts.data.data.length;
          for (let i = 0; i < emptyRows; i++) {
            leaveCounts.data.data.push({
              id: uuidv4(),
              firstname: '',
              lastname: '',
              site: '',
              enter_date: '',
              tags: [],
              user_leave_counts: [],
            });
          }
        }

        // Add rows
        const rows = leaveCounts.data.data.map((user) => {
          const row = {
            ...user,
            id: user.id,
            name: getFullname(user),
            site: user.site?.name,
            date: user.enter_date
              ? dayjs(user.enter_date).format('DD/MM/YYYY')
              : '',
            teams: user.tags,
          };

          // Add columns
          addColumns(user.user_leave_counts, row);
          return row;
        });
        setRows(rows);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const initData = async () => {
    getUserCounters(1, []);
  };

  const onPageChange = (page) => {
    setMetaData((prevState) => ({
      ...prevState,
      current_page: page + 1,
    }));
  };

  const toggleTeamModal = () => {
    setIsTeamModalOpen((prevState) => !prevState);
  };
  const toggleCreateModal = () => {
    setIsCreateModalOpen((prevState) => !prevState);
  };
  const toggleUpdateModal = () => {
    setIsUpdateModalOpen((prevState) => !prevState);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen((prevState) => !prevState);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prevState) => !prevState);
  };

  const findTag = (tag) => {
    const found = teamModalData?.teams?.find((item) => item.id === tag.id);
    return Boolean(found);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    initData();
  }, [sort]);

  const exportToExcel = (values, props) => {
    props.setSubmitting(true);
    congesService
      .post(
        '/v1/export-leave-count?from_team=1&withCurrentUser=1',
        {
          file_name: values.file_name,
          filters,
        },
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        exportDocHelper(
          res.data,
          'application/vnd.ms-excel',
          values.file_name,
          'xls'
        );
        toggleExportModal();
        ToastCustom.validated(t('successExport'));
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error(t('errorExportCounters'));
      })
      .finally(() => props.setSubmitting(false));
  };

  return (
    <>
      <h1 className={'mb-6 text-2xl'}>{t('teams')}</h1>
      <div className='bg-white w-full rounded-md p-7'>
        <div className='flex sm:justify-between mb-7 justify-end'>
          <h2 className='sm:flex items-center text-lg hidden'>
            {t('collabsCount')}
          </h2>
          <div className='flex gap-x-3 items-center'>
            <ButtonThirdActionWithIcon
              onClick={toggleExportModal}
              icon={
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className={classNames(
                    'm-3 font-medium group-hover:text-button-hover group-active:text-button-clicked text-lightgrey-600 group-hover:cursor-pointer'
                  )}
                />
              }
            >
              {t('export')}
            </ButtonThirdActionWithIcon>

            <ButtonSecondAction
              onClick={() => toggleFilterModal()}
              type='button'
            >
              {t('filters')}
            </ButtonSecondAction>

            <FilterButton
              selectedOptions={filters}
              onClick={() => {
                setFilters([]);
                setSelectedOptions({});
                getUserCounters();
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className='flex justify-between mb-8'>
              <div className='flex items-center gap-x-4'>
                <ButtonFilled
                  onClick={toggleCreateModal}
                  icon={
                    <FontAwesomeIcon
                      icon={faPlus}
                      size='lg'
                      className={classNames(
                        'text-primary1 m-3 font-bold group-hover:text-button-hover group-active:text-button-clicked'
                      )}
                    />
                  }
                >
                  {t('createTeam')}
                </ButtonFilled>
                <ButtonThirdActionWithIcon
                  onClick={toggleUpdateModal}
                  icon={
                    <FontAwesomeIcon
                      icon={faPeopleGroup}
                      className={classNames(
                        'm-3 font-medium group-hover:text-button-hover group-active:text-button-clicked text-lightgrey-600 group-hover:cursor-pointer'
                      )}
                    />
                  }
                >
                  {t('updateTeam')}
                </ButtonThirdActionWithIcon>
                {isDirector && <AddCollaborator />}
              </div>
            </div>
            <DataGridCustomGroup
              columns={columns}
              rows={rows}
              isLoading={isLoading}
              metadata={metaData}
              getData={getUserCounters}
              filters={filters}
              setMetaData={setMetaData}
              onPageChange={onPageChange}
              groupColumn={groupColumn}
              onSortModelChange={handleSortModelChange}
            />
          </>
        )}
      </div>
      <TeamModal
        isOpen={isTeamModalOpen}
        toggleTeamModal={toggleTeamModal}
        teamModalData={teamModalData}
        tags={tags}
        metaData={metaData}
        findTag={findTag}
        getUserCounters={getUserCounters}
      />
      <CreateModal
        getTags={getTags}
        isOpen={isCreateModalOpen}
        onModalClose={toggleCreateModal}
        id={currentUser.id}
      />
      <UpdateModal
        getTags={getTags}
        getUserCounters={getUserCounters}
        tags={tags}
        setTags={setTags}
        isOpen={isUpdateModalOpen}
        onModalClose={toggleUpdateModal}
        id={currentUser.id}
      />
      <ExportModal
        isOpen={isExportModalOpen}
        onModalClose={toggleExportModal}
        exportToExcel={exportToExcel}
      />
      <FilterData
        manager={() => {
          return TeamConfig(
            setFilters,
            getUserCounters,
            tags,
            setIsFilterModalOpen,
            selectedOptions,
            setSelectedOptions
          );
        }}
        isOpen={isFilterModalOpen}
        setIsOpen={setIsFilterModalOpen}
      />
    </>
  );
};

export default Teams;
